import React from 'react'
import { PageHeader, Seo } from '../components'
// Styles
import {
  Wrapper,
  Container,
  Title,
  StyledSpan,
  StyledImage,
} from '../styles/CorporatePage.style'

// Data
import { usePrismic } from '../context/PrismicContext'

const AboutNew = () => {
  const {
    prismicData: { prismicAboutPage },
  } = usePrismic()

  const {
    title,
    best_stars_image,
    best_stars_title,
    best_stars_content,
    making_difference_image,
    making_difference_title,
    making_difference_content,
    spreading_quintessential_image,
    spreading_quintessential_title,
    spreading_quintessential_content,
  } = prismicAboutPage

  return (
    <>
      <Seo title="About" />
      <Wrapper>
        <PageHeader url="/">{title[0].text}</PageHeader>

        <Container>
          <StyledImage src={best_stars_image.url} />
        </Container>
        <Container padded noPaddingBottom>
          <Title bigger>{best_stars_title[0].text}</Title>
        </Container>
        <Container padded>
          {best_stars_content.map((item, index) => (
            <StyledSpan key={`bestStarts-${index}`}>{item.text}</StyledSpan>
          ))}
        </Container>

        <Container>
          <StyledImage src={making_difference_image.url} />
        </Container>
        <Container padded noPaddingBottom>
          <Title bigger>{making_difference_title[0].text}</Title>
        </Container>
        <Container padded>
          {making_difference_content.map((item, index) => (
            <StyledSpan key={`makingDifference-${index}`}>
              {item.text}
            </StyledSpan>
          ))}
        </Container>

        <Container>
          <StyledImage src={spreading_quintessential_image.url} />
        </Container>
        <Container padded noPaddingBottom>
          <Title bigger>{spreading_quintessential_title[0].text}</Title>
        </Container>
        <Container padded noPaddingBottom>
          {spreading_quintessential_content.map((item, index) => (
            <StyledSpan key={`spreadingQuintessential-${index}`}>
              {item.text}
            </StyledSpan>
          ))}
        </Container>
      </Wrapper>
    </>
  )
}

export default AboutNew
